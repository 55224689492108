import { NavLink } from 'react-router-dom';
import ClientList from './components/ClientList';
import { useState } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { TerminationDetailsModal, useTermination } from 'features/termination';

const ForTermination = ({ attribute }) => {
  const { STATUS } = useTermination();

  const [reloadList, setReloadList] = useState(true);
  const [selectedClient, setSelectedClient] = useState({});
  const [openTerminationModal, setOpenTerminationModal] = useState(false);

  const ActionComponent = ({ row }) => {
    return (
      <button
        onClick={() => {
          setSelectedClient(row);
          setOpenTerminationModal(true);
        }}
        className="border-2 border-grayscale-700 rounded-10 py-1.5 px-2 text-sm items-center space-x-2 flex font-bold tracking-2 leading-1.5 text-grayscale-700 hover:bg-grayscale-500"
      >
        <SearchIcon className="w-3 h-3" />
        <span>{row.status === STATUS.PENDING ? 'Review' : 'View'}</span>
      </button>
    );
  };

  return (
    <div>
      <TerminationDetailsModal
        open={openTerminationModal}
        client={selectedClient?.client}
        setOpen={setOpenTerminationModal}
        termination={selectedClient.termination}
        onEvaluate={() => setReloadList(!reloadList)}
      />

      <ClientList
        reload={reloadList}
        ActionComponent={ActionComponent}
        title="For Termination"
        additionalFilters={[]}
        initialFilters={[
          {
            attribute,
            operator: 'EQ',
            value: true,
          },
        ]}
        defaultColumns={[
          'client',
          'service',
          'brandStrategist',
          'contractSigned',
          'termination.createdAt',
        ]}
        allowedFilters={[
          'status',
          'accountStatus',
          'subscriptionStatus',
          'terminatedAt',
          'terminationReason',
          'brandStrategist',
          'accountSpecialist',
          'subscriptionPaymentOption',
        ]}
        excludeColumns={[
          'terminatedAt',
          'invoiceSummary',
          'totalPaidInvoiceAmount',
          'totalUnPaidInvoiceAmount',
        ]}
        initialParams={{
          page: 1,
          pageSize: 10,
          sorts: 'client:asc',
          search: '',
          filters: '[]',
          include: [
            'termination',
            'subscription',
            'defaultContact',
            'projectManager[user[avatar]]',
            'brandStrategist[user[avatar]]',
            'accountSpecialist[user[avatar]]',
          ],
          attributes: [
            'phone',
            'client',
            'status',
            'service',
            'address',
            'website',
            'isOnboarding',
            'accountStatus',
            'amazonPageUrl',
            'agencyClientId',
            'draftMarketplace',
            'contractSigned',
            'terminationReason',
            'salesClientId',
            'subscriptionPaymentOption',
            'hasInvalidAssignee',
          ],
        }}
      >
        <div className="flex text-grayscale-700">
          <NavLink
            activeClassName="bg-white text-secondary-light"
            className="py-4 px-8 text-13 leading-1.5 font-bold"
            to="/clients/fortermination"
            exact
          >
            Requests
          </NavLink>

          <NavLink
            activeClassName="bg-white text-secondary-light"
            className="py-4 px-8 text-13 leading-1.5 font-bold"
            to="/clients/fortermination/denied"
            exact
          >
            Denied
          </NavLink>
        </div>
      </ClientList>
    </div>
  );
};

export default ForTermination;
