import axios from 'axios';
import moment from 'moment';
import { debounce, isEmpty } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router';
import { object, string, number, ref } from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Transition, Menu, Disclosure } from '@headlessui/react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { useEffect, Fragment, useState, useCallback, useRef } from 'react';
import ReactQuill from 'react-quill';

import {
  XIcon,
  LinkIcon,
  TrashIcon,
  RefreshIcon,
  ChevronUpIcon,
  ClipboardIcon,
  PlusCircleIcon,
  ChevronDownIcon,
  ExternalLinkIcon,
} from '@heroicons/react/outline';

import Button from 'components/Button';
import Loading from 'components/Loading';
import Label from 'components/Forms/Label';
import DropdownMenu from 'components/DropdownMenu';
import UsersPopover from 'components/UsersPopover';
import ConfirmationModal from 'components/ConfirmationModal';
import UpdatesToggle from 'components/Updates/UpdatesToggle';

import { setAlert } from 'features/alerts/alertsSlice';
import { selectAuthenticatedUser } from 'features/auth/authSlice';
import { setShowUpdatesSection } from '../leadsSlice';

import Updates from '../Updates';
import ContactDetails from './forms/ContactDetails';
import LeadStatusSlideOver from './LeadStatusSlideOver';

import { joiAlertErrorsStringify, agoUTC } from 'utils/formatters';

import usePermissions from 'hooks/usePermissions';

import {
  LABEL_VALUE,
  STATUS_REQUIRED,
  STATUSES_ARCHIVED,
  STATUSES_BOOKED,
  STATUSES_FOLLOW_UP,
  BOLD_MAP,
} from '../utils/constants';
import { Modal } from 'components';
import LemlistAcceptedModal from './Modal/LemlistAcceptedModal';
import useClientAssignees from 'features/clients/Client/useClientAssignees';
import { set } from 'dot-object';

moment.tz.setDefault('America/Toronto');

const LeadForm = ({
  action,
  initialValues,
  status,
  refresh,
  setRefresh,
  showAssignedButton,
  setShowAssignedButton,
  additionalContactsObj,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const { userCan } = usePermissions();
  const {
    SALES_REPRESENTATIVE_ASSIGNEE,
    LEAD_REPRESENTATIVE_ASSIGNEE,
    BUSINESS_DEVELOPMENT_REPRESENTATIVE_ASSIGNEE,
  } = useClientAssignees();
  const me = useSelector(selectAuthenticatedUser);
  const { showUpdatesSection } = useSelector((state) => state.leads);
  const Quill = ReactQuill.Quill;
  const Block = Quill.import('blots/block');
  Block.tagName = 'div';
  Quill.register(Block);

  const dispatch = useDispatch();
  const [params, setParams] = useState({
    lead: '',
    companyName: '',
    leadLastName: '',
  });
  const [assignee, setAssignee] = useState({
    leadsRep: '',
    salesRep: '',
    pitcher: '',
    bdRep: '',
  });

  const [rejectionChecklist, setRejectionChecklist] = useState([
    { key: 'competitor product not relevant', value: false },
    { key: 'competitor product revenue too low', value: false },
    {
      key: 'competitor product revenue too high / brand too established',
      value: false,
    },
    { key: 'Poor screenshots', value: false },
    { key: 'Lead is Reseller / AMZ Vendor Central', value: false },
    { key: 'Lead storefront Revenue under $5000', value: false },
  ]);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isOpenConfirmationUpdate, setIsOpenConfirmationUpdate] =
    useState(false);
  const [notes, setNotes] = useState([]);
  const [like, setLike] = useState([]);
  const [maxLike, setMaxLike] = useState(5);
  const [isAdd, setIsAdd] = useState(action === 'add' ? true : false);
  const [newLeadId, setNewLeadId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isStatusDisabled, setIsStatusDisabled] = useState(false);
  const [newVal, setNewVal] = useState({});
  const ninetyDays = 7776000000;
  const [assignToMe, setAssignToMe] = useState([]);
  const [currentStatus, setCurrentStatus] = useState('');
  const [leadReps, setLeadReps] = useState([]);
  const [salesReps, setSalesReps] = useState([]);
  const [bdReps, setBdReps] = useState([]);
  const [pitchers, setPitchers] = useState([]);
  const [availablePitchTemplates, setAvailablePitchTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [variables, setVariables] = useState({});
  // const [pitchTemplates, setPitchTemplates] = useState({});
  const [liAccounts, setLiAccounts] = useState({});
  const [isLemlistFormOpen, setIsLemlistFormOpen] = useState(false);
  const [openConfirmPitchViaEmail, setOpenConfirmPitchViaEmail] =
    useState(false);

  const [pitchCondition, setPitchCondition] = useState(false);
  const variablesOptions =
    variables && variables.rows
      ? variables.rows
          .find((el) => el.key === 'leadMarketPlace')
          .value.split('\n')
      : [];

  const defaultPitchTemplate = (
    leadvariable,
    linkedInPitchTemplate,
    isManuallyChanged
  ) => {
    let pitchVariableStatus = [
      { key: 'Follow Up 1', value: 'pitchTemplateFollowUp1' },
      { key: 'Follow Up 2', value: 'pitchTemplateFollowUp2' },
      { key: 'Follow Up 3', value: 'pitchTemplateFollowUp3' },
      { key: 'Follow Up 4', value: 'pitchTemplateFollowUp4' },
      { key: 'Old-Leads', value: 'pitchTemplateOldLeads' },
    ];

    let pitchVariableSource = [
      { key: 'Old-Leads', value: '2025PitchTemplateOLD' },
      { key: 'New Leads', value: '2025PitchTemplateNEW' },
      { key: 'Unprocessed New Leads', value: '2025PitchTemplateNEW' },
    ];

    const pitchTemplateValue = isManuallyChanged
      ? isManuallyChanged
      : pitchVariableSource.some((el) => el.key === leadvariable.source)
      ? pitchVariableSource.find((el) => el.key === leadvariable.source).value
      : pitchVariableStatus.some((el) => el.key === leadvariable.status)
      ? pitchVariableStatus.find((el) => el.key === leadvariable.status).value
      : linkedInPitchTemplate
      ? linkedInPitchTemplate
      : 'defaultPitchTemplate';

    const templates = variables?.rows
      ? variables.rows
          .filter((el) => el.key.includes('itchTemplate'))
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      : [];
    setAvailablePitchTemplates(templates);

    return templates.some((el) => el.key === pitchTemplateValue)
      ? templates.find((el) => el.key === pitchTemplateValue).value
      : '';
  };

  const onChangeTemplate = (e, values, setFieldValue) => {
    e.preventDefault();
    setSelectedTemplate(e.target.value);
    setFieldValue(
      'pitchTemplate',
      pitchTemplateMaker(values, variables, e.target.value)
    );
  };

  const [productCategoryOptions, setProductCategoryOptions] = useState(['']);
  const [source, setSource] = useState('');

  const liAccountsOptions =
    liAccounts && liAccounts.rows ? liAccounts.rows : [];

  const productCategoryOptions2 =
    productCategoryOptions.length > 2 ? productCategoryOptions : [''];

  const exactMatch =
    like.length > 0
      ? like.find(
          (el) =>
            el.companyName.toLowerCase() == params.companyName.toLowerCase()
        )
      : undefined;
  const isDisabled =
    (params.companyName.length < 1 && initialValues.companyName.length < 1) ||
    showAssignedButton ||
    like.length > 0
      ? true
      : false;

  const formRef = useRef();
  const contentRef = useRef();

  useEffect(async () => {
    ReactTooltip.rebuild();
    let countParams = {
      leadsRep: me.userId,
    };

    await axios
      .get(`/agency/leads/count`, {
        params: countParams,
      })
      .then((res) => {
        let resCount = res.data.data;
        setAssignToMe(resCount.AssignedToMe.rows);
      });

    let variableResponse = await axios.get(`/agency/leads/variables`, {
      params: { pageSize: 1000 },
    });

    setVariables(variableResponse.data.data);

    axios
      .get(`/agency/leads/liAccounts?sort=name:asc NULLS LAST`, {
        params: { pageSize: 1000, status: 'active' },
      })
      .then((res) => {
        setLiAccounts(res.data.data);
      });
  }, []);

  useEffect(() => {
    if (initialValues.marketplace) {
      productCategorySetter(initialValues.marketplace);
    }
  }, [variables]);

  const currencyGetter = (m) => {
    const mpCurrency = [
      { mp: '.com', currency: '$' },
      { mp: '.ca', currency: '$' },
      { mp: '.de', currency: '€' },
      { mp: '.uk', currency: '£' },
      { mp: '.co.uk', currency: '£' },
      { mp: '.co.jp', currency: '¥' },
      { mp: '.in', currency: '₹' },
      { mp: '.es', currency: '€' },
      { mp: '.fr', currency: '€' },
      { mp: '.it', currency: '€' },
      { mp: '.pl', currency: 'zł' },
      { mp: '.se', currency: 'kr' },
    ];
    return mpCurrency.find((el) => el.mp === m)
      ? mpCurrency.find((el) => el.mp === m).currency
      : '';
  };

  const urlGetter = (type, varValue) => {
    let url = '';
    if (type === 'Low' || type === 'None') {
      url = varValue.rows.find((el) => el.key === 'lowCalendlyUrl').value;
    } else if (type === 'Medium') {
      url = varValue.rows.find((el) => el.key === 'mediumCalendlyUrl').value;
    } else if (type == 'High') {
      url = varValue.rows.find((el) => el.key === 'highCalendlyUrl').value;
    } else {
      url = varValue.rows.find((el) => el.key === 'ultraHighCalendlyUrl').value;
    }
    return url;
  };

  const numConverter = (num) => {
    if (!num) {
      return '';
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const pitchTemplateMaker = (
    leadVariable,
    varValue,
    isManuallyChanged = ''
  ) => {
    let linkedInPitchTemplate =
      leadVariable.linkedInAccountId &&
      liAccountsOptions.some(
        (el) => el.linkedInAccountId === leadVariable.linkedInAccountId
      )
        ? liAccountsOptions.find(
            (el) => el.linkedInAccountId === leadVariable.linkedInAccountId
          ).pitchTemplate
        : null;

    if (varValue && varValue.rows) {
      let pitchTemplateVariable = `${defaultPitchTemplate(
        leadVariable,
        linkedInPitchTemplate,
        isManuallyChanged
      )}`;

      for (const el of LABEL_VALUE) {
        const re = new RegExp(`--${el.label}--`, 'ig');

        pitchTemplateVariable = pitchTemplateVariable.replaceAll(
          re,
          el.isNumber
            ? numConverter(leadVariable[el.value])
            : leadVariable[el.value]
        );
      }
      pitchTemplateVariable = pitchTemplateVariable.replaceAll(
        new RegExp(`--currencyGetter--`, 'ig'),
        currencyGetter(leadVariable.marketplace)
      );
      pitchTemplateVariable = pitchTemplateVariable.replaceAll(
        new RegExp(`--urlGetter--`, 'ig'),
        urlGetter(leadVariable.leadQuality, varValue)
      );
      pitchTemplateVariable = pitchTemplateVariable.replaceAll(
        new RegExp(`--LinkedIn Account--`, 'ig'),
        liAccountsOptions.find(
          (el) => el.linkedInAccountId === leadVariable.linkedInAccountId
        )
          ? liAccountsOptions.find(
              (el) => el.linkedInAccountId === leadVariable.linkedInAccountId
            ).name
          : ''
      );

      function replaceWordsBetweenDashBDash(str) {
        const regex = /-b-([\w\s.,?!@#$%^&*()\-+=\\|[\]{};:'"<>/_]+?)-b-/gi;
        let result = str;

        result = result.replace(regex, (match, word) => {
          return match.replace(word, convertToBold(word));
        });
        result = result.replace(/-b-/g, '');

        return result;
      }

      return replaceWordsBetweenDashBDash(pitchTemplateVariable);
    } else {
      return '';
    }
  };

  const marketPlaceKey = [
    { key: '.ca', val: 'productCategoryCanada' },
    { key: '.com', val: 'productCategoryUsa' },
    { key: '.co.uk', val: 'productCategoryUk' },
    { key: '.co.jp', val: 'productCategoryJap' },
    { key: '.de', val: 'productCategoryDe' },
    { key: '.in', val: 'productCategoryIn' },
    { key: '.es', val: 'productCategoryEs' },
    { key: '.fr', val: 'productCategoryFr' },
    { key: '.it', val: 'productCategoryIt' },
    { key: '.pl', val: 'productCategoryPl' },
    { key: '.se', val: 'productCategorySe' },
  ];

  const productCategorySetter = (marketplace) => {
    setProductCategoryOptions(
      marketPlaceKey.find((el) => el.key === marketplace) &&
        variables.rows.find(
          (el) =>
            el.key === marketPlaceKey.find((el) => el.key === marketplace).val
        )
        ? variables.rows
            .find(
              (el) =>
                el.key ===
                marketPlaceKey.find((el) => el.key === marketplace).val
            )
            .value.split('\n')
            .map((el) => el.toLowerCase())
        : variables.rows
            .find((el) => el.key === 'productCategoryUsa')
            .value.split('\n')
            .map((el) => el.toLowerCase())
    );
  };

  const convertToBold = (text) => {
    let result = '';
    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      result += BOLD_MAP[char] || char;
    }
    return result;
  };

  useEffect(() => {
    initialValues.pitchTemplate = pitchTemplateMaker(
      initialValues,
      variables,
      selectedTemplate
    );
    if (initialValues.rejectionReasons) {
      let rejectionReasons = initialValues.rejectionReasons.split(',');
      let newRejectionChecklist = [...rejectionChecklist];

      setRejectionChecklist(
        newRejectionChecklist.map((el) => {
          return {
            ...el,
            value: rejectionReasons.includes(el.key),
          };
        })
      );
    }

    setCurrentStatus(initialValues.status);
    setSource(initialValues.source);

    if (action !== 'add' && initialValues.companyName) {
      axios
        .get(
          `/agency/leads/duplicate/${initialValues.companyName}?website=${initialValues.website}&brandName=${initialValues.brandName}`
        )
        .then((res) => {
          let data = res.data.data;
          data.map((rec) => {
            if (!rec.agencyClientId) return false;
            return dispatch(
              setAlert(
                'info',
                `Potential-duplicate with client`,
                `Client Brand name: ${rec.client}`,
                12000,
                `/clients/edit/${rec.agencyClientId}`
              )
            );
          });
        });
    }

    // Get sales reps
    axios
      .get('/v2/users', {
        params: {
          filterGroups: JSON.stringify([
            {
              filters: [
                {
                  attribute: 'role.code',
                  operator: 'IN',
                  value: SALES_REPRESENTATIVE_ASSIGNEE.allowedRoles.map(
                    (role) => role.value
                  ),
                },
              ],
            },
          ]),
        },
      })
      .then((res) => {
        setSalesReps(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // Get BD reps
    axios
      .get('/v2/users', {
        params: {
          filterGroups: JSON.stringify([
            {
              filters: [
                {
                  attribute: 'role.code',
                  operator: 'IN',
                  value:
                    BUSINESS_DEVELOPMENT_REPRESENTATIVE_ASSIGNEE.allowedRoles.map(
                      (role) => role.value
                    ),
                },
              ],
            },
          ]),
        },
      })
      .then((res) => {
        setBdReps(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // Get Lead reps
    axios
      .get('/v2/users', {
        params: {
          attributes: [
            'userId',
            'roleId',
            'firstName',
            'lastName',
            'email',
            'supervisorId',
            'lastName',
            'hiringDate',
            'deletedAt',
            'status',
          ],
          filterGroups: JSON.stringify([
            {
              filters: [
                {
                  attribute: 'role.code',
                  operator: 'IN',
                  value: LEAD_REPRESENTATIVE_ASSIGNEE.allowedRoles.map(
                    (role) => role.value
                  ),
                },
              ],
            },
          ]),
        },
      })
      .then((res) => {
        let activeUsers = res.data.data.filter(
          (el) => el.status !== 'INACTIVE'
        );
        setLeadReps(activeUsers);
        setPitchers(activeUsers);
      })
      .catch((err) => {
        console.log(err);
      });

    setAssignee({
      leadsRep: action === 'add' ? me.userId : initialValues.leadsRep,
      salesRep: initialValues.salesRep,
      pitcher: initialValues.pitcher,
      bdRep: initialValues.bdRep,
    });
    setIsStatusDisabled(false);
  }, [initialValues, refresh]);

  useEffect(() => {
    if (params.companyName.length > 1) {
      setIsLoading(true);
      axios.get(`/agency/leads/duplicate`, { params }).then((res) => {
        let likeData = res.data.data.filter((el) => el.leadId !== id);
        setPitchCondition(
          likeData.find(
            (el) =>
              params.companyName.toLowerCase() ===
                el.companyName.toLowerCase() &&
              Date.now() < Date.parse(el.pitchedDate) + ninetyDays
          )
        );

        setLike(res.data.data.filter((el) => el.leadId !== id));

        setMaxLike(5);
        setIsLoading(false);
      });
    }
  }, [params.companyName]);

  const onCreateLead = async (values) => {
    values.podId = me.memberId && me.memberId.podId ? me.memberId.podId : null;
    // values.processedBy = me.userId;
    values.leadsRep = me.userId;
    values.notes = notes;
    // values.status = 'New Leads';
    Object.keys(values).map(function (key, index) {
      if (values[key] === '') delete values[key];
    });
    delete values.addedBy;
    delete values.requestedByUser;
    delete values.liAccountUsed;
    delete values.requestedByUser;

    let payload = values.competitorBrandName
      ? {
          ...values,
          competitors: [
            {
              competitorScreenShotURL: values.competitorScreenShotURL,
              competitorBrandName: values.competitorBrandName,
              revenue: values.revenue,
              competitorAsinRevenueScreenshotDateStamp:
                values.competitorAsinRevenueScreenshotDateStamp,
              competitorAsinRevenueScreenshot:
                values.competitorAsinRevenueScreenshot
                  ? values.competitorAsinRevenueScreenshot
                  : '',
              CompetitorId: values.competitors[0]?.CompetitorId,
            },
          ],
        }
      : { ...values };

    try {
      setIsLoading(true);
      const response = await axios.post('/agency/leads', payload);
      if (response.data.success) {
        if (
          initialValues.status !== values.status &&
          (values.status === 'Call-Booked' ||
            values.status === 'Direct-Booking' ||
            values.status === 'Lemlist Direct-Booking' ||
            values.status === 'Email Pending')
        ) {
          try {
            let salesData = {
              leadId: response.data.data.leadId,
              status:
                values.status === 'Email Pending'
                  ? 'Email Pending'
                  : 'first call',
            };
            const res = await axios.post(`/agency/sales/clients/`, salesData);

            // if (res.data.success) {
            //   console.log(res.data);
            // }
          } catch (error) {
            const errorMessages = joiAlertErrorsStringify(error);
            dispatch(
              setAlert('error', error.response.data.message, errorMessages)
            );
          }
        }

        dispatch(setAlert('success', 'Lead Profile Saved'));
        setIsAdd(false);
        setNewLeadId(response.data.data.leadId);
        setIsLoading(false);
        history.push(`/leads/profile/${response.data.data.leadId}`);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    }
  };

  const onUpdateLead = async (val) => {
    let data = { ...val, status: currentStatus };

    let leadId = id !== 'create' ? id : newLeadId;

    // Delete unused fields
    Object.keys(data).map(function (key, index) {
      if (data[key] === '') delete data[key];
    });

    if (
      initialValues.status !== data.status &&
      (data.status === 'Call-Booked' ||
        data.status === 'Direct-Booking' ||
        data.status === 'Lemlist Direct-Booking' ||
        data.status === 'Email Pending')
    ) {
      try {
        let salesData = {
          leadId: leadId,
          salesRepId: data.salesRep,
          leadRepId: data.leadsRep,
          firstCallDate: data.dateOfCall,
          status:
            data.status === 'Email Pending' ? 'Email Pending' : 'first call',
        };
        const response = await axios.post(`/agency/sales/clients/`, salesData);

        // if (response.data.success) {
        //   console.log(response.data);
        // }
      } catch (error) {
        const errorMessages = joiAlertErrorsStringify(error);
        dispatch(setAlert('error', error.response.data.message, errorMessages));
      }
    }

    if (rejectionChecklist.filter((el) => el.value).length > 0) {
      let rejectionReasons = rejectionChecklist
        .filter((el) => el.value)
        .map((el) => el.key)
        .toString();

      data.rejectionReasons = rejectionReasons;
    }

    delete data.addedBy;
    delete data.requestedByUser;
    delete data.liAccountUsed;
    delete data.pitchDate;

    try {
      let payload = data.competitorBrandName
        ? {
            ...data,
            competitors: [
              {
                competitorScreenShotURL: data.competitorScreenShotURL,
                competitorBrandName: data.competitorBrandName,
                revenue: data.revenue,
                competitorAsinRevenueScreenshotDateStamp:
                  data.competitorAsinRevenueScreenshotDateStamp,
                competitorAsinRevenueScreenshot:
                  data.competitorAsinRevenueScreenshot
                    ? data.competitorAsinRevenueScreenshot
                    : '',
                CompetitorId: data.competitors[0]?.CompetitorId,
              },
            ],
          }
        : { ...data };
      setIsLoading(true);
      const response = await axios.put(`/agency/leads/${leadId}`, payload);
      if (response.data.success) {
        dispatch(setAlert('success', 'Lead Profile Saved'));
        setIsLoading(false);
        if (id === 'create') {
          history.push(`/leads/profile/${leadId}`);
        } else {
          setRefresh(!refresh);
        }
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    }
  };

  const onSubmit = (values) => {
    if (isAdd) {
      onCreateLead({ ...values, status: currentStatus });
    } else {
      if (
        status !== currentStatus &&
        [
          'Direct-Booking',
          'Lemlist Direct-Booking',
          'Accepted Positive',
          'Accepted Neutral',
          'Accepted Negative',
          'Call-Booked',
        ].includes(currentStatus)
      ) {
        setIsStatusOpen(true);
      } else if (
        [
          'Lemlist Accepted Positive',
          'Lemlist Accepted Neutral',
          'Lemlist Accepted Negative',
        ].includes(currentStatus)
      ) {
        setIsLemlistFormOpen(true);
      } else {
        onUpdateLead(values);
      }
    }
  };

  const onViewProfile = (rec) => {
    if (assignToMe.length > 0) {
      assignToMe.map((rec) => {
        dispatch(
          setAlert(
            'info',
            `In-progress in ${rec.status}`,
            `Company name: ${rec.companyName}`,
            12000,
            `/leads/profile/${rec.leadId}`
          )
        );
      });
    } else {
      setLike([]);
      if ('lead' in rec) {
        window.open(`/leads/profile/${rec.leadId}`, '_blank');
      } else {
        window.open(`/clients/profile/${rec.leadId}`, '_blank');
      }
    }
  };

  const onCancel = async (e, values) => {
    e.preventDefault();
    history.push('/leads');
  };

  const onCopyTemplate = async (e, template) => {
    e.preventDefault();
    contentRef.current.focus();
    document.execCommand('selectAll');
    document.execCommand('copy');
    ReactTooltip.rebuild();
  };

  const canSendEmail = (values) => {
    if (values.status !== 'Ready to Pitch') return false;
    if (!values.profileAsinRevenueScreenshot?.url) return false;
    if (!values.competitors[0]?.competitorAsinRevenueScreenshot) return false;
    if (!values.email) return false;
    if (!userCan('leads.pitch.email')) return false;
    return true;
  };

  const onSendViaEmail = async (
    e,
    template,
    email,
    linkedInAccountId,
    values
  ) => {
    e.preventDefault();
    const fTag = template.substring(0, 3).includes('p')
      ? 'p'
      : template.substring(0, 5).includes('div')
      ? 'div'
      : false;

    let doc = new DOMParser().parseFromString(template, 'text/html');
    let firstPtag = doc?.querySelector(fTag)?.textContent;
    let regexp = /\Subject:(.*)/;
    let signature = liAccountsOptions.find(
      (el) => el.linkedInAccountId === linkedInAccountId
    )?.signature;
    let name = liAccountsOptions.find(
      (el) => el.linkedInAccountId === linkedInAccountId
    )?.name;
    let attachment = `<div style="display: flex;">
      <div style="flex: 50%; padding: 5px;">
        <img src="${values.profileAsinRevenueScreenshot.url}" alt="revenue" style="width:100%">
      </div>
      <div style="flex: 50%; padding: 5px;">
        <img src="${values.competitors[0].competitorAsinRevenueScreenshot}" alt="competitor revenue" style="width:100%">
      </div>
    </div>`;

    if (fTag && firstPtag && regexp.test(firstPtag)) {
      let subject = firstPtag.match(regexp)[1].trim();
      let content = template
        .replace(firstPtag.match(regexp)[0], '')
        .replace(name, '');
      let body = `${content} ${attachment} ${signature}`;
      const payload = {
        subject,
        body,
        email,
        name,
      };

      try {
        setIsLoading(true);
        const response = await axios.post(
          `/agency/leads/${id}/send-email`,
          payload
        );
        setIsLoading(false);
        if (response.data.success) {
          dispatch(setAlert('success', 'Lead Profile Saved'));
          setRefresh(!refresh);
        }
      } catch (error) {
        const errorMessages = joiAlertErrorsStringify(error);
        dispatch(setAlert('error', error.response.data.message, errorMessages));
      }
      setOpenConfirmPitchViaEmail(false);
    } else {
      dispatch(setAlert('error', 'Error in pitch template'));
    }
    setIsLoading(false);
  };

  const onChangeSource = (val, setFieldValue) => {
    setSource(val);
    if (action === 'add') {
      if (val === 'Shopify' || val === 'Instagram') {
        setCurrentStatus(val);
        setFieldValue('status', val);
        setIsStatusDisabled(true);
      } else {
        setCurrentStatus('For Approval');
        setFieldValue('status', 'For Approval');
        setIsStatusDisabled(false);
      }
    }
  };

  const updateParamsSearch = async (
    { lead, companyName, leadLastName, params },
    type
  ) => {
    if (type === 'lead') {
      setParams({ ...params, lead });
    } else if (type === 'companyName') {
      setParams({ ...params, companyName });
    } else if (type === 'leadLastName') {
      setParams({ ...params, leadLastName });
    }
  };

  const debouncedUpdateSearch = useCallback(
    debounce((value, type) => updateParamsSearch(value, type), 200),
    []
  );

  const onSearchLead = (e) => {
    debouncedUpdateSearch({ lead: e.target.value, params }, 'lead');
  };

  const onSearchCompanyName = (e) => {
    debouncedUpdateSearch(
      { companyName: e.target.value, params },
      'companyName'
    );
  };

  const validationSchema = object().shape(
    STATUSES_ARCHIVED.includes(currentStatus) ||
      ![
        'New Leads',
        'Old-Leads',
        'LinkedIn',
        '',
        'Shopify',
        'Instagram',
      ].includes(source) ||
      action === 'add' ||
      userCan('leads.responses')
      ? {}
      : {
          lead: string().required('Lead First Name - Required'),
          leadLastName: string().required('Lead Last Name - Required'),
          secondaryLeadFirstName: string(),
          secondaryLeadLastName: string(),
          secondaryPhoneNumber: string(),
          secondaryEmailAddress: string(),
          companyName: string().required('Company Name - Required'),
          leadType: string().required('Fullfilment - Required'),
          brandName: string().required('Brand Name- Required'),
          amzStoreFBAstoreFront: string()
            .url()
            .required('Amazon Store Front URL - Required'),
          asin: string().required('ASIN - Required'),
          asinFullTitle: string().required('ASIN Full Title- Required'),
          storeFrontEarnings: number()
            .min(0)
            .required('Storefront Revenue - Required'),
          linkedInProfileURL: string()
            .url()
            .required('LinkedIn Profile URL - Required'),
          // linkedInProfilePictureURL: string()
          //   .url()
          //   .required('LinkedIn Profile Picture URL - Required'),
          currentEarnings: number().min(0).required('ASIN Revenue - Required'),
        }
  );

  const linkedInUrlPatternValidation = (URL) => {
    const regex = new RegExp(
      '((https?://)?((www|ww).)?linkedin.com/)((([w]{2,3})?)|([^/]+/(([w|d-&#?=])+/?){1,}))$'
    );
    return regex.test(URL);
  };

  const bookedAndAcceptedStatus = [
    'Call-Booked',
    'Direct-Booking',
    'Accepted Positive',
    'Accepted Neutral',
    'Accepted Negative',
    'Accepted Disqualified',
    'Lemlist Direct-Booking',
    'Lemlist Accepted Positive',
    'Lemlist Accepted Neutral',
    'Lemlist Accepted Negative',
  ];

  const statusOptions = [
    {
      for: 'Unprocessed New Leads',
      options:
        action === 'add' &&
        ![
          'New Leads',
          'Old-Leads',
          'Shopify',
          'Instagram',
          'LinkedIn',
          '',
        ].includes(source)
          ? ['Unprocessed New Leads', 'Ready to Pitch', 'Call-Booked']
          : action === 'add'
          ? [
              'Unprocessed New Leads',
              'Shopify',
              'For Approval',
              'Ready to Pitch',
            ]
          : [
              'Unprocessed New Leads',
              'For Approval',
              'Ready to Pitch',
              'Archived',
            ],
    },
    {
      for: 'Old-Leads',
      options: userCan('leads.approve')
        ? [
            'Old-Leads',
            'For Approval',
            'Ready to Pitch',
            'Archived',
            'Shopify',
            'Instagram',
            ...bookedAndAcceptedStatus,
          ]
        : [
            'Old-Leads',
            'For Approval',
            'Ready to Pitch',
            'Archived',
            ...bookedAndAcceptedStatus,
          ],
    },
    {
      for: 'Shopify',
      options: userCan('leads.approve')
        ? ['Shopify', 'Ready to Pitch', 'Archived']
        : ['Shopify', 'Ready to Pitch', 'Archived'],
    },
    {
      for: 'Instagram',
      options: userCan('leads.approve')
        ? ['Instagram', 'Ready to Pitch', 'Archived']
        : ['Instagram', 'Ready to Pitch', 'Archived'],
    },
    {
      for: 'System-duplicate',
      options: userCan('leads.approve')
        ? ['System-duplicate', 'For Approval', 'Ready to Pitch', 'Archived']
        : ['System-duplicate', 'For Approval', 'Ready to Pitch', 'Archived'],
    },
    {
      for: 'Potential-duplicate',
      options: userCan('leads.approve')
        ? ['Potential-duplicate', 'For Approval', 'Ready to Pitch', 'Archived']
        : ['Potential-duplicate', 'For Approval', 'Ready to Pitch', 'Archived'],
    },
    {
      for: 'Old-Lead Pending Approval',
      options: userCan('leads.approve')
        ? [
            'Old-Lead Pending Approval',
            'Ready to Pitch',
            'Revision',
            'Rejected',
          ]
        : ['Old-Lead Pending Approval'],
    },
    {
      for: 'For Approval',
      options: userCan('leads.approve')
        ? ['For Approval', 'Ready to Pitch', 'Revision', 'Rejected']
        : ['For Approval'],
    },
    {
      for: 'New Leads',
      options: userCan('leads.approve')
        ? [
            'New Leads',
            'No LinkedIn Available',
            'Unqualified',
            'Ready to Pitch',
          ]
        : [
            'New Leads',
            'No LinkedIn Available',
            'Unqualified',
            'Ready to Pitch',
          ],
    },
    // Archived
    {
      for: 'No LinkedIn Available',
      options: userCan('leads.approve')
        ? [
            'No LinkedIn Available',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'No LinkedIn Available',
            'For Approval',
            'Ready to Pitch',
            'Email Pending',
            'Archived',
          ],
    },

    {
      for: 'Linkedin Messaging Disabled',
      options: userCan('leads.approve')
        ? [
            'Linkedin Messaging Disabled',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'Linkedin Messaging Disabled',
            'For Approval',
            'Ready to Pitch',
            'Email Pending',
            'Archived',
          ],
    },

    {
      for: 'Unqualified',
      options: userCan('leads.approve')
        ? [
            'Unqualified',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : ['Unqualified', 'For Approval', 'Ready to Pitch', 'Archived'],
    },
    {
      for: 'Inmail Declined',
      options: userCan('leads.approve')
        ? [
            'Inmail Declined',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : ['Inmail Declined', 'For Approval', 'Ready to Pitch', 'Archived'],
    },
    {
      for: 'Out of Stock',
      options: userCan('leads.approve')
        ? [
            'Out of Stock',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : ['Out of Stock', 'For Approval', 'Ready to Pitch', 'Archived'],
    },
    {
      for: 'Drip',
      options: userCan('leads.approve')
        ? [
            'Drip',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'Drip',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ],
    },
    {
      for: 'Disengaged Old Lead',
      options: userCan('leads.approve')
        ? [
            'Disengaged Old Lead',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'Disengaged Old Lead',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ],
    },
    {
      for: 'SF under $1000',
      options: userCan('leads.approve')
        ? [
            'SF under $1000',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'SF under $1000',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ],
    },

    {
      for: 'No Competitor',
      options: userCan('leads.approve')
        ? [
            'No Competitor',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'No Competitor',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ],
    },
    {
      for: 'AMZ',
      options: userCan('leads.approve')
        ? [
            'AMZ',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'AMZ',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ],
    },
    {
      for: 'Other Marketplace',
      options: userCan('leads.approve')
        ? [
            'Other Marketplace',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'Other Marketplace',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ],
    },

    {
      for: 'Duplicate',
      options: userCan('leads.approve')
        ? [
            'Duplicate',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'Duplicate',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ],
    },
    {
      for: 'Reseller',
      options: userCan('leads.approve')
        ? [
            'Reseller',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'Reseller',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ],
    },
    {
      for: 'Not Interested',
      options: userCan('leads.approve')
        ? [
            'Not Interested',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'Not Interested',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ],
    },

    {
      for: 'Chinese Seller',
      options: userCan('leads.approve')
        ? [
            'Chinese Seller',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'Chinese Seller',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ],
    },
    {
      for: 'No Seller Info',
      options: userCan('leads.approve')
        ? [
            'No Seller Info',
            'Unprocessed New Leads',
            'For Approval',
            'Ready to Pitch',
            'Email Pending',
            'Archived',
          ]
        : ['No Seller Info'],
    },

    {
      for: 'Do Not Contact',
      options: userCan('leads.approve')
        ? [
            'Do Not Contact',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'Do Not Contact',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ],
    },
    {
      for: 'Working with Another Agency',
      options: userCan('leads.approve')
        ? [
            'Working with Another Agency',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'Working with Another Agency',
            'For Approval',
            'Ready to Pitch',
            'Email Pending',
            'Archived',
          ],
    },

    {
      for: 'Incorrect Contact',
      options: userCan('leads.approve')
        ? [
            'Incorrect Contact',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : ['Incorrect Contact', 'For Approval', 'Ready to Pitch', 'Archived'],
    },

    {
      for: 'Previous / Existing Client',
      options: userCan('leads.approve')
        ? [
            'Previous / Existing Client',
            'For Approval',
            'Ready to Pitch',
            ...bookedAndAcceptedStatus,
            'Email Pending',
            'Archived',
          ]
        : [
            'Previous / Existing Client',
            'For Approval',
            'Ready to Pitch',
            'Email Pending',
            'Archived',
          ],
    },
    // Archived
    {
      for: 'Ready to Pitch',
      options:
        userCan('leads.revise') || userCan('leads.approve')
          ? [
              'Ready to Pitch',
              'Connection Request Sent',
              'Pitched',
              'Revision',
              'Archived',
            ]
          : ['Ready to Pitch', 'Connection Request Sent', 'Pitched'],
    },

    {
      for: 'Connection Request Sent',
      options:
        userCan('leads.revise') || userCan('leads.approve')
          ? ['Connection Request Sent', 'Pitched', 'Revision', 'Archived']
          : ['Connection Request Sent', 'Pitched'],
    },
    {
      for: 'Revision',
      options: userCan('leads.approve')
        ? ['Revision', 'For Approval', 'Ready to Pitch', 'Archived']
        : ['Revision', 'For Approval', 'Ready to Pitch', 'Archived'],
    },
    {
      for: 'Rejected',
      options: userCan('leads.approve')
        ? ['Rejected', 'For Approval', 'Ready to Pitch']
        : ['Rejected', 'For Approval', 'Ready to Pitch'],
    },
    {
      for: 'Email Pending',
      options: userCan('leads.approve')
        ? [
            'Email Pending',
            'Accepted Positive',
            'Call-Booked',
            ...STATUSES_FOLLOW_UP,
            'Archived',
          ]
        : [
            'Email Pending',
            'Accepted Positive',
            'Call-Booked',
            ...STATUSES_FOLLOW_UP,
            'Archived',
          ],
    },
    {
      for: 'Pitched',
      options: userCan('leads.booked')
        ? [
            'Pitched',
            'Direct-Booking',
            'Accepted Positive',
            'Accepted Neutral',
            'Accepted Negative',
            'Accepted Disqualified',
            'Email Pending',
            'Ready to Pitch',
            'Archived',
          ]
        : userCan('leads.approve')
        ? [
            'Pitched',
            'Accepted Positive',
            'Accepted Neutral',
            'Accepted Negative',
            'Accepted Disqualified',
            'Email Pending',
            'Ready to Pitch',
            'Inmail Declined',
          ]
        : ['Pitched'],
    },
    {
      for: 'Lemlist',
      options: userCan('leads.booked')
        ? [
            'Lemlist',
            'Lemlist Direct-Booking',
            'Direct-Booking',
            'Accepted Positive',
            'Accepted Neutral',
            'Accepted Negative',
            'Accepted Disqualified',
            'Lemlist Accepted Positive',
            'Lemlist Accepted Neutral',
            'Lemlist Accepted Negative',
            'Email Pending',
            'Ready to Pitch',
            'Archived',
          ]
        : userCan('leads.approve')
        ? [
            'Lemlist',
            'Accepted Positive',
            'Accepted Neutral',
            'Accepted Negative',
            'Accepted Disqualified',
            'Lemlist Accepted Positive',
            'Lemlist Accepted Neutral',
            'Lemlist Accepted Negative',
            'Email Pending',
            'Ready to Pitch',
            'Inmail Declined',
          ]
        : ['Lemlist'],
    },
    {
      for: 'Accepted Positive',
      options: userCan('leads.booked')
        ? [
            'Accepted Positive',
            'Call-Booked',
            ...STATUSES_FOLLOW_UP,
            'Email Pending',
            'Archived',
          ]
        : ['Accepted Positive'],
    },
    {
      for: 'Accepted Neutral',
      options: userCan('leads.booked')
        ? [
            'Accepted Neutral',
            'Call-Booked',
            ...STATUSES_FOLLOW_UP,
            'Email Pending',
            'Archived',
          ]
        : ['Accepted Neutral'],
    },
    {
      for: 'Accepted Negative',
      options: userCan('leads.booked')
        ? [
            'Accepted Negative',
            'Accepted Disqualified',
            'Call-Booked',
            ...STATUSES_FOLLOW_UP,
            'Email Pending',
            'Archived',
          ]
        : ['Accepted Negative'],
    },
    {
      for: 'Accepted Disqualified',
      options: userCan('leads.booked')
        ? [
            'Accepted Disqualified',
            'Accepted Negative',
            'Call-Booked',
            ...STATUSES_FOLLOW_UP,
            'Email Pending',
            'Archived',
          ]
        : ['Accepted Disqualified'],
    },
    {
      for: 'Lemlist Accepted Positive',
      options: userCan('leads.booked')
        ? [
            'Lemlist Accepted Positive',
            'Call-Booked',
            ...STATUSES_FOLLOW_UP,
            'Email Pending',
            'Archived',
          ]
        : ['Accepted Positive'],
    },
    {
      for: 'Lemlist Accepted Neutral',
      options: userCan('leads.booked')
        ? [
            'Lemlist Accepted Neutral',
            'Call-Booked',
            ...STATUSES_FOLLOW_UP,
            'Email Pending',
            'Archived',
          ]
        : ['Lemlist Accepted Neutral'],
    },
    {
      for: 'Lemlist Accepted Negative',
      options: userCan('leads.booked')
        ? [
            'Lemlist Accepted Negative',
            'Call-Booked',
            ...STATUSES_FOLLOW_UP,
            'Email Pending',
            'Archived',
          ]
        : ['Lemlist Accepted Negative'],
    },
    {
      for: 'Follow Up 1',
      options: [
        ...STATUSES_FOLLOW_UP,
        'Email Pending',
        'Call-Booked',
        'Potential Leads',
        'Archived',
      ],
    },

    {
      for: 'Follow Up 2',
      options: [
        ...STATUSES_FOLLOW_UP,
        'Email Pending',
        'Call-Booked',
        'Potential Leads',
        'Archived',
      ],
    },

    {
      for: 'Follow Up 3',
      options: [
        ...STATUSES_FOLLOW_UP,
        'Email Pending',
        'Call-Booked',
        'Potential Leads',
        'Archived',
      ],
    },

    {
      for: 'Follow Up 4',
      options: [
        ...STATUSES_FOLLOW_UP,
        'Email Pending',
        'Call-Booked',
        'Potential Leads',
        'Archived',
      ],
    },

    {
      for: 'Potential Leads',
      options: ['Call-Booked', 'Archived'],
    },

    {
      for: 'Direct-Booking',
      options: userCan('leads.approve')
        ? ['Direct-Booking', 'Lemlist Direct-Booking']
        : ['Direct-Booking'],
    },

    {
      for: 'Call-Booked',
      options: userCan('leads.approve') ? ['Call-Booked'] : ['Call-Booked'],
    },
    {
      for: 'RepliedTo',
      options: userCan('leads.booked')
        ? ['RepliedTo', 'Call-Booked']
        : ['RepliedTo'],
    },
    {
      for: 'Client',
      options: userCan('leads.approve') ? ['Client'] : ['Client'],
    },
  ];

  const reponseOptions = [
    'Pitched',
    'Direct-Booking',
    'Lemlist Direct-Booking',
    'Accepted Positive',
    'Accepted Neutral',
    'Call-Booked',
    'RepliedTo',
    'Client',
  ];

  const onUpdateExistingRecord = () => {
    setIsOpenConfirmation(false);
    setLike([]);
    history.push(`/leads/profile/${exactMatch.leadId}`);
  };

  const onContinueAnyway = (e) => {
    if (exactMatch) {
      setIsOpenConfirmation(true);
    } else {
      setNotes(like);
      setLike([]);
    }

    e.preventDefault();
  };

  const onChangeStatus = (e, setFieldValue, values) => {
    e.preventDefault();

    setCurrentStatus(e.target.value);
    let value = e.target.value;
    let prevStatus = initialValues.status;
    let dateNow = moment().format('YYYY-MM-DD HH:mm:ss');
    setFieldValue('statusUpdatedDate', dateNow);
    setFieldValue('prevStatus', prevStatus);
    setFieldValue('status', value);

    if (
      ['Old-Leads', 'Unprocessed New Leads'].includes(prevStatus) ||
      !values.processedDate
    ) {
      setFieldValue('processedDate', dateNow);
    }
    if (
      ['Old-Leads', 'Unprocessed New Leads'].includes(prevStatus) ||
      !values.pitchDate
    ) {
      setFieldValue('pitchDate', dateNow);
    }

    if (value === 'Ready to Pitch') {
      setFieldValue('approvedDate', dateNow);
      setFieldValue('approvalBy', me.userId);
    }
    if (!values.leadsRep) {
      setFieldValue('leadsRep', me.userId);
    }

    if (value === 'For Approval') {
      setFieldValue('forApprovalDate', dateNow);
    }
    if (value === 'Pitched') {
      setFieldValue('pitchedDate', dateNow);
      if (!values.pitcher) {
        setFieldValue('pitcher', me.userId);
      }
    }
    if (value === 'Lemlist') {
      setFieldValue('lemlistDate', dateNow);
    }
    if (
      [
        'Lemlist Accepted Positive',
        'Lemlist Accepted Neutral',
        'Lemlist Accepted Negative',
        'Do Not Contact',
      ].includes(value)
    ) {
      setFieldValue('lemlistResponseDate', dateNow);
    }
    if (
      ['Follow Up 1', 'Follow Up 2', 'Follow Up 3', 'Follow Up 4'].includes(
        value
      )
    ) {
      setFieldValue('isFollowUpSent', false);
    }
    if (value === 'Revision') {
      setFieldValue('revisionDate', dateNow);
    }
    if (value === 'Rejected') {
      setFieldValue('rejectedDate', dateNow);
    }
    if (STATUSES_ARCHIVED.includes(value)) {
      setFieldValue('archivedDate', dateNow);
      setFieldValue('archivedBy', me.userId);
    }
    if (STATUSES_BOOKED.includes(value)) {
      setFieldValue('dateBooked', dateNow);
      setFieldValue('bookedBy', me.userId);
      setFieldValue('isInSales', true);
    }
    if (STATUSES_BOOKED.includes(value) || value === 'Email Pending') {
      setFieldValue('isInSales', true);
    }

    if (
      [
        'Accepted Positive',
        'Accepted Neutral',
        'Accepted Negative',
        'Accepted Disqualified',
        'Direct-Booking',
        'Lemlist Direct-Booking',
      ].includes(value)
    ) {
      setFieldValue('dateTimeOfResponse', dateNow);
      setFieldValue('responseType', value);
      setFieldValue('responsedBy', me.userId);
    }
  };

  const onConfirmUpdate = () => {
    onUpdateLead(newVal);
  };

  const onCheck = (e, el, i) => {
    let copy = [...rejectionChecklist];
    copy[i].value = !copy[i].value;
    setRejectionChecklist(copy);
  };

  const onUpdatePitchTemplate = (e, field, values, setFieldValue) => {
    setFieldValue(
      'pitchTemplate',
      pitchTemplateMaker({ ...values, [field]: e.target.value }, variables)
    );
  };

  const onChangeStoreFront = (e, values, setFieldValue) => {
    let val = parseInt(e.target.value);
    if (val < 10001) {
      setFieldValue('leadQuality', 'Low');
      setFieldValue(
        'pitchTemplate',
        pitchTemplateMaker({ ...values, leadQuality: 'Low' }, variables)
      );
    } else if (val < 50001) {
      setFieldValue('leadQuality', 'Medium');
      setFieldValue(
        'pitchTemplate',
        pitchTemplateMaker({ ...values, leadQuality: 'Medium' }, variables)
      );
    } else if (val < 150001) {
      setFieldValue('leadQuality', 'High');
      setFieldValue(
        'pitchTemplate',
        pitchTemplateMaker({ ...values, leadQuality: 'High' }, variables)
      );
    } else {
      setFieldValue('leadQuality', 'Ultra-High');
      setFieldValue(
        'pitchTemplate',
        pitchTemplateMaker({ ...values, leadQuality: 'Ultra-High' }, variables)
      );
    }
  };

  const onPhasteImage = (e, setFieldValue, field, fieldStamp) => {
    const dT = e.clipboardData || window.clipboardData;
    const file = dT.files[0];
    if (file) {
      getBase64(file)
        .then((result) => {
          setFieldValue(field, result);
          setFieldValue(fieldStamp, moment().format('YYYY-MM-DD HH:mm:ss'));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setFieldValue(field, '');
      alert('No image found');
    }
  };

  const onUploadImage = (e, setFieldValue, field, fieldStamp, values) => {
    if (!e.target.files.length) return;
    const file = e.target.files[0];
    const form = new FormData();
    form.append('file', file);
    form.append('field', field);
    form.append('leadId', id);
    form.append(
      'isCompetitor',
      field === 'competitorAsinRevenueScreenshot' ? true : false
    );

    const hasCompetitor = values.competitors.length > 0;
    form.append('hasCompetitor', hasCompetitor);
    form.append(
      'competitorId',
      hasCompetitor ? values.competitors[0].CompetitorId : null
    );
    form.append('competitorBrandName', values.competitorBrandName ?? '');
    form.append(
      'competitorScreenShotURL',
      values.competitorScreenShotURL ?? ''
    );
    form.append('revenue', values.revenue ?? '');
    let newAsinRevenueScreenshot = null;
    axios
      .post(`/agency/leads/profile-screenshot/${id}`, form)
      .then((response) => {
        if (field === 'asinRevenueScreenshot') {
          newAsinRevenueScreenshot = response.data.data.data.id;
        }

        dispatch(setAlert('success', response.data.message));
      })
      .catch(() =>
        dispatch(setAlert('error', 'Failed to upload profile picture.'))
      )
      .finally(() => {
        let data = {
          ...values,
          asinRevenueScreenshot: newAsinRevenueScreenshot,
        };
        onUpdateLead(data);
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = '';
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const copyToClipboard = async (link) => {
    navigator.clipboard.writeText(link);
    dispatch(setAlert('success', 'Link copied to clipboard', link));
  };

  const onRemoveScreenshot = async (
    fileId,
    setFieldValue,
    field,
    fieldStamp,
    CompetitorId = null,
    values
  ) => {
    const isCompetitor = field === 'competitorAsinRevenueScreenshot';
    axios
      .delete(`/agency/leads/profile-screenshot/${id}`, {
        params: { fileId, field, isCompetitor, CompetitorId },
      })
      .then((response) => {
        if (field === 'asinRevenueScreenshot') {
          setFieldValue(field, null);
          setFieldValue(fieldStamp, null);
        }
        dispatch(setAlert('success', response.data.message));
      })
      .catch(() =>
        dispatch(setAlert('error', 'Failed to remove profile picture.'))
      )
      .finally(() => {
        let data = { ...values, asinRevenueScreenshot: null };
        onUpdateLead(data);
      });
  };

  const RequiredField = ({ short }) => {
    return STATUS_REQUIRED.includes(currentStatus) ? (
      <span className="text-red-600 text-xs font-light tracking-tighter">
        {short ? '[*Req]' : '[*Required]'}
      </span>
    ) : (
      ''
    );
  };

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const checkErrors = (errors) => {
    Object.keys(errors).map(function (key) {
      dispatch(setAlert('error', errors[key]));
    });
  };

  const onMarkedAsDuplicate = async (e) => {
    e.preventDefault();
    let data = {
      status: 'Duplicate',
    };

    try {
      setIsLoading(true);
      const response = await axios.put(`/agency/leads/${id}`, data);
      if (response.data.success) {
        dispatch(setAlert('success', 'Lead Profile Saved'));
        setIsLoading(false);
        setShowAssignedButton(false);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    }
  };

  const onMarkedAsNotDuplicate = async (e) => {
    e.preventDefault();
    let data = {
      status: initialValues.prevStatus,
    };
    try {
      setIsLoading(true);
      const response = await axios.put(`/agency/leads/${id}`, data);
      if (response.data.success) {
        dispatch(
          setAlert('success', `Lead Profile Returned to Status: ${data.status}`)
        );
        setIsLoading(false);
        setShowAssignedButton(false);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    }
  };
  const onUpdateAssign = async (val, prop) => {
    setAssignee({
      ...assignee,
      [prop]: val,
    });

    try {
      setIsLoading(true);
      const response = await axios.put(`/agency/leads/${id}`, {
        [prop]: val,
      });
      if (response.data.success) {
        dispatch(setAlert('success', 'Lead Profile Saved'));
        setIsLoading(false);
        setShowAssignedButton(false);
        setRefresh(!refresh);
      }
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      dispatch(setAlert('error', error.response.data.message, errorMessages));
    }
  };

  const onDeleteAdditionalContact = async (additionalContact) => {
    try {
      const response = await axios.delete(
        `/agency/leads/additionalContact/${additionalContact.leadsAdditionalContactsId}`
      );
      if (response.data.success) {
        dispatch(setAlert('success', 'Additional contact removed'));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onDeleteCompetitor = async (obj) => {
    try {
      const response = await axios.delete(
        `/agency/sales/competitor/${obj?.CompetitorId}`
      );
      if (response.data.success) {
        dispatch(setAlert('success', 'Competitor removed'));
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
    }
  };
  function validateLinkedInProfileURL(value) {
    let error;
    if (
      !/(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/.test(
        value
      )
    ) {
      error = 'Please enter a valid LinkedIn profile URL';
    }
    return error;
  }

  const handleFilePath = (path) => {
    const newPath =
      process.env.REACT_APP_FILE_URL +
      '/' +
      path.substring(path.indexOf('/files') + 1);
    return newPath;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {({ handleChange, setFieldValue, values, errors }) => (
          <Form onKeyDown={onKeyDown}>
            <div className="grid grid-cols-12 gap-x-6 gap-y-4">
              <div className="col-span-7">
                <div className="grid grid-cols-12 gap-x-2 gap-y-4">
                  <div className="col-span-4">
                    <div className="flex">
                      <Label classes="flex-initial pr-5 mt-2">Status</Label>
                      <Menu
                        as="div"
                        className="relative left-0 inline-block text-left w-full"
                      >
                        <div>
                          <Menu.Button
                            disabled={isStatusDisabled}
                            className="inline-flex w-full cursor-auto bg-gray-50 rounded-md px-4 py-2 text-sm font-medium focus:ring-gray-200 focus:border-gray-200"
                          >
                            {currentStatus}
                            <ChevronDownIcon
                              className="ml-2 -mr-1 h-4 w-4 text-gray-400"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          {/* <Menu.Items className="z-10 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"> */}
                          <Menu.Items className="overflow-y-scroll min-h-80 h-96 z-10 absolute mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {statusOptions.find((e) => e.for === status) &&
                              statusOptions
                                .find((e) => e.for === status)
                                .options.map((s, i) => {
                                  return s === 'Archived' ? (
                                    <Menu.Item key={i}>
                                      {({ active }) => (
                                        <DropdownMenu
                                          title="Archived"
                                          classes={`${
                                            active && 'bg-gray-200 '
                                          } bg-transparent group rounded-md text-sm`}
                                          position=" relative -top-0"
                                          dropdownWidth="w-48"
                                          buttonBg="white"
                                          textColor="black"
                                          buttonClasses="justify-start w-40 hover:bg-gray-200"
                                          // hoverClasses="border-gray-200"
                                          padding="p-2"
                                          justifyStart={true}
                                          // popperStyling={false}
                                          children={STATUSES_ARCHIVED.map(
                                            (s2, idx2) => {
                                              return (
                                                <Menu.Item key={idx2}>
                                                  {({ active }) => (
                                                    <button
                                                      value={s2}
                                                      className={`${
                                                        active && 'bg-red-100 '
                                                      } bg-transparent group flex  w-80 w-full p-2 text-sm`}
                                                      onClick={(e) => {
                                                        onChangeStatus(
                                                          e,
                                                          setFieldValue,
                                                          values
                                                        );
                                                      }}
                                                    >
                                                      {s2}
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                              );
                                            }
                                          )}
                                        />
                                      )}
                                    </Menu.Item>
                                  ) : (
                                    <Menu.Item key={i}>
                                      {({ active }) => (
                                        <button
                                          className={`${
                                            active && 'bg-gray-200 '
                                          } group flex rounded-md w-full p-2 text-sm`}
                                          value={s}
                                          onClick={(e) => {
                                            onChangeStatus(
                                              e,
                                              setFieldValue,
                                              values
                                            );
                                          }}
                                        >
                                          {s}
                                        </button>
                                      )}
                                    </Menu.Item>
                                  );
                                })}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>

                  <div className="col-span-4">
                    <div className="flex">
                      <Label classes="flex-initial pr-5 mt-2">Quality</Label>
                      <Field
                        name="leadQuality"
                        as="select"
                        className="form-select text-sm"
                        disabled={true}
                      >
                        <option key="Low" value="Low">
                          Low
                        </option>
                        <option key="Medium" value="Medium">
                          Medium
                        </option>
                        <option key="High" value="High">
                          High
                        </option>
                        <option key="Ultra-High" value="Ultra-High">
                          Ultra-High
                        </option>
                      </Field>
                    </div>
                  </div>
                  <div className="col-span-4">
                    <div className="flex">
                      <Label classes="flex-initial pr-5 mt-2">
                        Booking source
                      </Label>
                      <Field
                        name="bookingSource"
                        as="select"
                        className="form-select text-sm"
                      >
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Email">Email</option>
                        <option value="Website">Website</option>
                        <option value="Social (Organic and Paid)">
                          Social (Organic and Paid)
                        </option>
                        <option value="Networking Events">
                          Networking Events
                        </option>
                      </Field>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-5">
                <div className="flex flex-row-reverse items-center">
                  {initialValues.pitchedDate && (
                    <div className="flex flex-col px-1 text-center">
                      <div>
                        <UsersPopover
                          users={salesReps}
                          selectedId={assignee.salesRep}
                          onChange={(user) =>
                            onUpdateAssign(user.userId, 'salesRep')
                          }
                          disabled={!userCan('leads.admin.team.lead')}
                          position="left-0 sm:left-auto sm:right-0"
                        />
                      </div>
                      <span className="text-1xs text-gray-400">
                        Sales
                        <br />
                        Representative
                      </span>
                    </div>
                  )}
                  {initialValues.pitchedDate && (
                    <div className="flex flex-col px-1 text-center">
                      <div>
                        <UsersPopover
                          users={bdReps}
                          selectedId={assignee.bdRep}
                          onChange={(user) =>
                            onUpdateAssign(user.userId, 'bdRep')
                          }
                          disabled={!userCan('leads.admin.team.lead')}
                          position="left-0 sm:left-auto sm:right-0"
                        />
                      </div>
                      <span className="text-1xs text-gray-400">
                        BD
                        <br />
                        Representative
                      </span>
                    </div>
                  )}

                  {(initialValues.approvedDate ||
                    initialValues.pitchedDate) && (
                    <div className="flex flex-col px-1 text-center">
                      <div>
                        <UsersPopover
                          users={pitchers}
                          selectedId={assignee.pitcher}
                          onChange={(user) =>
                            onUpdateAssign(user.userId, 'pitcher')
                          }
                          disabled={!userCan('leads.admin.team.lead')}
                          position="left-0 sm:left-auto sm:right-0"
                        />
                      </div>
                      <span className="text-1xs text-gray-400 ">
                        Lead
                        <br />
                        Pitcher
                      </span>
                    </div>
                  )}

                  <div className="flex flex-col px-1 text-center">
                    <div>
                      <UsersPopover
                        users={leadReps}
                        selectedId={assignee.leadsRep}
                        onChange={(user) =>
                          onUpdateAssign(user.userId, 'leadsRep')
                        }
                        disabled={!userCan('leads.admin.team.lead')}
                        position="left-0 sm:left-auto sm:right-0"
                      />
                    </div>
                    <span className="text-1xs text-gray-400 ">
                      Lead
                      <br />
                      Representative
                    </span>
                  </div>
                </div>
              </div>

              <div
                className={`${
                  showUpdatesSection ? 'col-span-7' : 'col-span-12'
                } `}
              >
                <div
                  className={`${
                    showUpdatesSection ? 'client-content' : 'col-span-10'
                  } container-fixed bg-white`}
                >
                  <div className="sm:grid grid-cols-1 gap-x-6 gap-y-4">
                    <div className="col-span-1">
                      {/* Contact Details */}
                      <Disclosure defaultOpen>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b">
                              <span>Contact Details</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? 'rotate-180 transform' : ''
                                } h-6 w-6 text-gray-500`}
                              />
                            </Disclosure.Button>

                            <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                              <ContactDetails
                                onSearchCompanyName={onSearchCompanyName}
                                handleChange={handleChange}
                                onSearchLead={onSearchLead}
                                onUpdatePitchTemplate={onUpdatePitchTemplate}
                                values={values}
                                setFieldValue={setFieldValue}
                                like={like}
                                maxLike={maxLike}
                                setMaxLike={setMaxLike}
                                onContinueAnyway={onContinueAnyway}
                                onViewProfile={onViewProfile}
                                pitchCondition={pitchCondition}
                                isDisabled={isDisabled}
                                initialValues={initialValues}
                                liAccountsOptions={liAccountsOptions}
                                currentStatus={currentStatus}
                                onChangeSource={onChangeSource}
                              />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="col-span-1">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <FieldArray name="additionalContacts">
                              {({ insert, remove, push }) => (
                                <>
                                  <Disclosure.Button
                                    disabled={showAssignedButton}
                                    className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b"
                                  >
                                    <span>
                                      Additional Contacts&nbsp;
                                      <span
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          push(additionalContactsObj);
                                        }}
                                        className="ml-2"
                                      >
                                        <PlusCircleIcon className="w-6 h-6 inline text-gray-700 hover:text-green-500 relative -mt-2" />
                                      </span>
                                    </span>
                                    <ChevronUpIcon
                                      className={`${
                                        open ? 'rotate-180 transform' : ''
                                      } h-6 w-6 text-gray-500`}
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="py-4 space-y-4 text-sm text-gray-500">
                                    {values.additionalContacts?.length > 0 &&
                                      values.additionalContacts.map(
                                        (additionalContact, index) => (
                                          <div
                                            key={index}
                                            className="sm:grid grid-cols-2 gap-4"
                                          >
                                            <div className="col-span-2 flex justify-between">
                                              <span className="font-bold text-base text-gray-700">
                                                Additional Contact {index + 1}
                                              </span>
                                              <span
                                                onClick={() => {
                                                  remove(index);
                                                  onDeleteAdditionalContact(
                                                    additionalContact
                                                  );
                                                }}
                                                className="cursor-pointer hover:text-red-500"
                                              >
                                                <TrashIcon className="w-5 h-5 inline" />
                                              </span>
                                            </div>
                                            <div className="col-span-1">
                                              <Label>Lead First Name</Label>
                                              <Field
                                                name={`additionalContacts.${index}.lead`}
                                                placeholder="Lead First Name"
                                                className="form-input disabled-white"
                                                onChange={(e) =>
                                                  handleChange(e)
                                                }
                                                type="text"
                                                disabled={isDisabled}
                                              />
                                            </div>

                                            <div className="col-span-1">
                                              <Label>Lead Last Name</Label>
                                              <Field
                                                name={`additionalContacts.${index}.leadLastName`}
                                                placeholder="Lead Last Name"
                                                className="form-input disabled-white"
                                                onChange={(e) =>
                                                  handleChange(e)
                                                }
                                                type="text"
                                                disabled={isDisabled}
                                              />
                                            </div>

                                            <div className="col-span-1">
                                              <Label>Phone Number</Label>
                                              <Field
                                                name={`additionalContacts.${index}.phoneNumber`}
                                                placeholder="Lead Phone Number"
                                                className="form-input disabled-white"
                                                onChange={(e) =>
                                                  handleChange(e)
                                                }
                                                type="text"
                                                disabled={isDisabled}
                                              />
                                            </div>

                                            <div className="col-span-1">
                                              <Label>Email</Label>
                                              <Field
                                                name={`additionalContacts.${index}.email`}
                                                placeholder="Lead Email"
                                                className="form-input disabled-white"
                                                onChange={(e) =>
                                                  handleChange(e)
                                                }
                                                type="text"
                                                disabled={isDisabled}
                                              />
                                            </div>

                                            <div className="col-span-1">
                                              <Label>
                                                LinkedIn Profile URL
                                              </Label>
                                              <Field
                                                name={`additionalContacts.${index}.linkedInUrl`}
                                                placeholder="LinkedIn Url"
                                                className="form-input disabled-white"
                                                type="text"
                                                validate={
                                                  validateLinkedInProfileURL
                                                }
                                              />
                                              <ErrorMessage
                                                name={`additionalContacts.${index}.linkedInUrl`}
                                                component="div"
                                                className="text-error text-tiny"
                                              />
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </Disclosure.Panel>
                                </>
                              )}
                            </FieldArray>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    {/* <div className="col-span-1">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b">
                              <span>Additional Contact Information</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? 'rotate-180 transform' : ''
                                } h-6 w-6 text-gray-500`}
                              />
                            </Disclosure.Button>

                            <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                              <>Additional contact</>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div> */}

                    {!isAdd && (
                      <>
                        <div className="col-span-1">
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  disabled={showAssignedButton}
                                  className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b"
                                >
                                  <span>Amazon Store and Product Details</span>
                                  <ChevronUpIcon
                                    className={`${
                                      open ? 'rotate-180 transform' : ''
                                    } h-6 w-6 text-gray-500`}
                                  />
                                </Disclosure.Button>

                                <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                                  <div className="grid grid-cols-12 gap-4">
                                    <div className="col-span-6">
                                      <Label>
                                        Brand Name {/* <RequiredField /> */}
                                      </Label>
                                      <Field
                                        name="brandName"
                                        placeholder="Brand Name"
                                        className="form-select text-sm"
                                        onChange={(e) => {
                                          handleChange(e);
                                          onUpdatePitchTemplate(
                                            e,
                                            'brandName',
                                            values,
                                            setFieldValue
                                          );
                                        }}
                                        type="text"
                                        disabled={isDisabled}
                                      />
                                      <ErrorMessage
                                        name="brandName"
                                        component="div"
                                        className="text-red-700 font-normal text-xs"
                                      />
                                    </div>

                                    <div className="col-span-6">
                                      <Label>
                                        Amazon Store Front URL{' '}
                                        {/* <RequiredField /> */}{' '}
                                        {values.amzStoreFBAstoreFront && (
                                          <LinkIcon
                                            onClick={() =>
                                              window.open(
                                                values.amzStoreFBAstoreFront,
                                                '_blank'
                                              )
                                            }
                                            className="cursor-pointer ml-2 inline h-5 w-5"
                                            color="gray"
                                          />
                                        )}
                                      </Label>
                                      <Field
                                        name="amzStoreFBAstoreFront"
                                        placeholder="Amazon Store Front URL"
                                        className="form-select text-sm"
                                        onChange={(e) => handleChange(e)}
                                        type="text"
                                        disabled={isDisabled}
                                      />
                                      <ErrorMessage
                                        name="amzStoreFBAstoreFront"
                                        component="div"
                                        className="text-red-700 font-normal text-xs"
                                      />
                                    </div>
                                    <div className="col-span-6">
                                      <Label>
                                        Product Category :
                                        {/* <RequiredField /> */}
                                      </Label>
                                      <Field
                                        name="productCategory"
                                        as="select"
                                        className="form-select text-sm capitalize"
                                        onChange={(e) => {
                                          handleChange(e);
                                          onUpdatePitchTemplate(
                                            e,
                                            'productCategory',
                                            values,
                                            setFieldValue
                                          );
                                        }}
                                        disabled={isDisabled}
                                      >
                                        <option
                                          className="capitalize"
                                          value={initialValues.productCategory}
                                        >
                                          {initialValues.productCategory}
                                        </option>
                                        {productCategoryOptions2.map(
                                          (el, i) => {
                                            return (
                                              <option
                                                key={i}
                                                className="capitalize"
                                                value={el}
                                              >
                                                {el}
                                              </option>
                                            );
                                          }
                                        )}
                                      </Field>
                                    </div>
                                    <div className="col-span-6">
                                      <Label>
                                        Marketplace {/* <RequiredField /> */}
                                      </Label>
                                      <Field
                                        name="marketplace"
                                        as="select"
                                        className="form-select text-sm"
                                        onChange={(e) => (
                                          productCategorySetter(e.target.value),
                                          handleChange(e),
                                          onUpdatePitchTemplate(
                                            e,
                                            'marketplace',
                                            values,
                                            setFieldValue
                                          )
                                        )}
                                        disabled={isDisabled}
                                      >
                                        <option value=""></option>
                                        {variablesOptions.map((rec, i) => {
                                          return (
                                            <option key={i} value={rec}>
                                              {rec}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    </div>
                                    <div className="col-span-6">
                                      <Label>Sub category 1</Label>
                                      <Field
                                        name="subCategory1"
                                        placeholder="Sub category 1"
                                        className="form-select text-sm"
                                        onChange={(e) => handleChange(e)}
                                        type="text"
                                        disabled={isDisabled}
                                      />
                                    </div>

                                    <div className="col-span-6">
                                      <Label>Sub category 2</Label>
                                      <Field
                                        name="subCategory2"
                                        placeholder="Sub category 2"
                                        className="form-select text-sm"
                                        onChange={(e) => handleChange(e)}
                                        type="text"
                                        disabled={isDisabled}
                                      />
                                    </div>

                                    <div className="col-span-9">
                                      <Label>
                                        ASIN Full Title{' '}
                                        {/* <RequiredField short={true} /> */}
                                      </Label>
                                      <Field
                                        name="asinFullTitle"
                                        placeholder="ASIN Full Title"
                                        className="form-select text-sm"
                                        onChange={(e) => handleChange(e)}
                                        type="text"
                                        disabled={isDisabled}
                                      />
                                      <ErrorMessage
                                        name="asinFullTitle"
                                        component="div"
                                        className="text-red-700 font-normal text-xs"
                                      />
                                    </div>

                                    <div className="col-span-3">
                                      <Label>No of ASIN's</Label>
                                      <Field
                                        name="noOfAsin"
                                        placeholder="No of ASIN's"
                                        className="form-select text-sm"
                                        onChange={(e) => handleChange(e)}
                                        type="number"
                                        disabled={isDisabled}
                                      />
                                      <ErrorMessage
                                        name="noOfAsin"
                                        component="div"
                                        className="text-red-700 font-normal text-xs"
                                      />
                                    </div>

                                    <div className="col-span-6">
                                      <Label>
                                        ASIN URL {/* <RequiredField /> */}{' '}
                                        {values.leadScreenShotURL && (
                                          <LinkIcon
                                            onClick={() =>
                                              window.open(
                                                values.leadScreenShotURL,
                                                '_blank'
                                              )
                                            }
                                            className="cursor-pointer ml-2 inline h-5 w-5"
                                            color="gray"
                                          />
                                        )}
                                      </Label>
                                      <Field
                                        name="leadScreenShotURL"
                                        placeholder="ASIN URL"
                                        className="form-select text-sm"
                                        onChange={(e) => handleChange(e)}
                                        type="text"
                                        disabled={isDisabled}
                                      />
                                      <ErrorMessage
                                        name="leadScreenShotURL"
                                        component="div"
                                        className="text-red-700 font-normal text-xs"
                                      />
                                    </div>

                                    <div className="col-span-6">
                                      <Label>
                                        ASIN {/* <RequiredField /> */}
                                      </Label>
                                      <Field
                                        name="asin"
                                        placeholder="ASIN"
                                        className="form-select text-sm"
                                        onChange={(e) => {
                                          handleChange(e);
                                          onUpdatePitchTemplate(
                                            e,
                                            'asin',
                                            values,
                                            setFieldValue
                                          );
                                        }}
                                        type="text"
                                        disabled={isDisabled}
                                      />
                                      <ErrorMessage
                                        name="asin"
                                        component="div"
                                        className="text-red-700 font-normal text-xs"
                                      />
                                    </div>

                                    <div className="col-span-6">
                                      <Label>ASIN Major Keyword</Label>
                                      <Field
                                        name="asinMajorKeyword"
                                        placeholder="ASIN Major Keyword"
                                        className="form-select text-sm"
                                        onChange={(e) => {
                                          handleChange(e);
                                          onUpdatePitchTemplate(
                                            e,
                                            'asinMajorKeyword',
                                            values,
                                            setFieldValue
                                          );
                                        }}
                                        type="text"
                                        disabled={isDisabled}
                                      />
                                    </div>

                                    <div className="col-span-6">
                                      <Label>Fullfilment</Label>
                                      <Field
                                        name="leadType"
                                        as="select"
                                        className="form-select text-sm"
                                        disabled={isDisabled}
                                      >
                                        <option value="None">None</option>
                                        <option value="FBA">FBA</option>
                                        <option value="FBM">FBM</option>
                                        <option value="Amazon">Amazon</option>
                                      </Field>
                                    </div>

                                    <div className="col-span-6">
                                      <Label>
                                        ASIN Price {/* <RequiredField /> */}
                                      </Label>
                                      <Field
                                        name="asinPrice"
                                        placeholder="ASIN Price"
                                        className="form-select text-sm"
                                        onChange={(e) => {
                                          handleChange(e);
                                          onUpdatePitchTemplate(
                                            e,
                                            'asinPrice',
                                            values,
                                            setFieldValue
                                          );
                                        }}
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        disabled={isDisabled}
                                      />
                                      <ErrorMessage
                                        name="asinPrice"
                                        component="div"
                                        className="text-red-700 font-normal text-xs"
                                      />
                                    </div>
                                    <div className="col-span-6">
                                      <Label>
                                        ASIN Reviews
                                        {/* <RequiredField short={true} /> */}
                                      </Label>
                                      <Field
                                        name="asinReviews"
                                        placeholder="ASIN Reviews"
                                        className="form-select text-sm"
                                        onChange={(e) => {
                                          handleChange(e);
                                          onUpdatePitchTemplate(
                                            e,
                                            'asinReviews',
                                            values,
                                            setFieldValue
                                          );
                                        }}
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        disabled={isDisabled}
                                      />
                                      <ErrorMessage
                                        name="asinReviews"
                                        component="div"
                                        className="text-red-700 font-normal text-xs"
                                      />
                                    </div>
                                    <div className="col-span-6">
                                      <div className="grid grid-cols-2 gap-4">
                                        <div className="col-span-2">
                                          <Label>
                                            ASIN Revenue &nbsp; &nbsp; &nbsp;
                                            {/* <RequiredField /> */}
                                          </Label>
                                          <Field
                                            name="currentEarnings"
                                            placeholder="ASIN Revenue"
                                            className="form-select text-sm"
                                            onChange={(e) => {
                                              handleChange(e);
                                              onUpdatePitchTemplate(
                                                e,
                                                'currentEarnings',
                                                values,
                                                setFieldValue
                                              );
                                            }}
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            disabled={isDisabled}
                                          />
                                          <ErrorMessage
                                            name="currentEarnings"
                                            component="div"
                                            className="text-red-700 font-normal text-xs"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-span-6">
                                      <Label>
                                        Storefront Revenue{' '}
                                        {/* <RequiredField /> */}
                                      </Label>
                                      <Field
                                        name="storeFrontEarnings"
                                        placeholder="Storefront Revenue"
                                        className="form-select text-sm"
                                        onChange={(e) => {
                                          handleChange(e);
                                          onChangeStoreFront(
                                            e,
                                            values,
                                            setFieldValue
                                          );
                                        }}
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        disabled={isDisabled}
                                      />
                                      <ErrorMessage
                                        name="storeFrontEarnings"
                                        component="div"
                                        className="text-red-700 font-normal text-xs"
                                      />
                                    </div>

                                    {values.profileAsinRevenueScreenshot &&
                                    !isEmpty(
                                      values.profileAsinRevenueScreenshot
                                    ) ? (
                                      <div className="col-span-12 row-span-3">
                                        <Label>
                                          ASIN Revenue Screenshot{' '}
                                          {/* <RequiredField /> */}
                                          <XIcon
                                            onClick={() =>
                                              onRemoveScreenshot(
                                                values
                                                  .profileAsinRevenueScreenshot
                                                  .id,
                                                setFieldValue,
                                                'asinRevenueScreenshot',
                                                'asinRevenueScreenshotDateStamp',
                                                null,
                                                values
                                              )
                                            }
                                            className="cursor-pointer ml-2 inline h-5 w-5"
                                            color="gray"
                                            data-tip="Change screenshot"
                                          />
                                          <ExternalLinkIcon
                                            onClick={() => {
                                              let image = new Image();
                                              image.src =
                                                values.profileAsinRevenueScreenshot.url;
                                              window
                                                .open('')
                                                .document.write(
                                                  image.outerHTML
                                                );
                                            }}
                                            className="cursor-pointer ml-2 inline h-5 w-5"
                                            color="gray"
                                            data-tip="Open in new tab"
                                          />
                                          <ClipboardIcon
                                            onClick={() =>
                                              copyToClipboard(
                                                values
                                                  .profileAsinRevenueScreenshot
                                                  .url
                                              )
                                            }
                                            className="cursor-pointer ml-2 inline h-5 w-5"
                                            color="gray"
                                            data-tip="Copy image"
                                          />
                                          <ReactTooltip
                                            place="bottom"
                                            className="max-w-xs text-black"
                                            backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                                            textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                                          />
                                        </Label>

                                        <img
                                          id="target"
                                          src={
                                            values.profileAsinRevenueScreenshot
                                              .url
                                          }
                                          data-tip={
                                            values.asinRevenueScreenshotDateStamp
                                          }
                                          className="border-2 border-indigo-600"
                                        />
                                        <ReactTooltip
                                          place="bottom"
                                          className="max-w-xs text-black"
                                          backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                                          textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                                        />
                                      </div>
                                    ) : (
                                      <div className="col-span-12 row-span-3">
                                        <div className="flex flex-row justify-between">
                                          <div className="flex flex-col">
                                            <Label>
                                              ASIN Revenue Screenshot
                                              {/* <RequiredField /> */}
                                            </Label>
                                            <input
                                              id="upload"
                                              className="pt-2 focus:outline-none"
                                              type="file"
                                              accept="image/*"
                                              onChange={(e) =>
                                                onUploadImage(
                                                  e,
                                                  setFieldValue,
                                                  'asinRevenueScreenshot',
                                                  'asinRevenueScreenshotDateStamp',
                                                  values
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        </div>

                        <div className="col-span-1">
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  disabled={showAssignedButton}
                                  className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b"
                                >
                                  <span>Competitor Comparable</span>
                                  <ChevronUpIcon
                                    className={`${
                                      open ? 'rotate-180 transform' : ''
                                    } h-6 w-6 text-gray-500`}
                                  />
                                </Disclosure.Button>

                                <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                                  <div className="col-span-12">
                                    <Label>
                                      Competitor Brand Name{' '}
                                      {/* <RequiredField /> */}
                                    </Label>
                                    <Field
                                      name="competitorBrandName"
                                      placeholder="Competitor Brand Name"
                                      className="form-select text-sm"
                                      onChange={(e) => {
                                        handleChange(e);
                                        onUpdatePitchTemplate(
                                          e,
                                          'competitorBrandName',
                                          values,
                                          setFieldValue
                                        );
                                      }}
                                      type="text"
                                      disabled={isDisabled}
                                    />
                                    <ErrorMessage
                                      name="competitorBrandName"
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="col-span-12">
                                    <Label>
                                      Competitor ASIN URL{' '}
                                      {/* <RequiredField /> */}
                                      {values.competitorScreenShotURL && (
                                        <LinkIcon
                                          onClick={() =>
                                            window.open(
                                              values.competitorScreenShotURL,
                                              '_blank'
                                            )
                                          }
                                          className="cursor-pointer ml-2 inline h-5 w-5"
                                          color="gray"
                                        />
                                      )}
                                    </Label>
                                    <Field
                                      name="competitorScreenShotURL"
                                      placeholder="Competitor ASIN URL"
                                      className="form-select text-sm"
                                      onChange={(e) => handleChange(e)}
                                      type="text"
                                      disabled={isDisabled}
                                    />
                                    <ErrorMessage
                                      name="competitorScreenShotURL"
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>

                                  <div className="col-span-12">
                                    <Label>
                                      Competitor ASIN Revenue{' '}
                                      {/* <RequiredField /> */}
                                    </Label>
                                    <Field
                                      name="revenue"
                                      placeholder="Competitor ASIN Revenue"
                                      className="form-select text-sm"
                                      onChange={(e) => handleChange(e)}
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      disabled={isDisabled}
                                    />
                                    <ErrorMessage
                                      name="revenue"
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="grid grid-cols-8 gap-4">
                                    {values.competitors.length > 0 &&
                                    values.competitors[0]
                                      .competitorAsinRevenueScreenshot ? (
                                      <div className="col-span-12 row-span-3">
                                        <Label>
                                          Competitor ASIN Revenue Screenshot
                                          <XIcon
                                            onClick={() =>
                                              onRemoveScreenshot(
                                                values.competitors[0].fileId,
                                                setFieldValue,
                                                'competitorAsinRevenueScreenshot',
                                                'competitorAsinRevenueScreenshotDateStamp',
                                                values.competitors[0]
                                                  .CompetitorId,
                                                values
                                              )
                                            }
                                            className="cursor-pointer ml-2 inline h-5 w-5"
                                            color="gray"
                                            data-tip="Change screenshot"
                                          />
                                          <ExternalLinkIcon
                                            onClick={() => {
                                              let image = new Image();
                                              image.src = handleFilePath(
                                                values.competitors[0]
                                                  .competitorAsinRevenueScreenshot
                                              );
                                              window
                                                .open('')
                                                .document.write(
                                                  image.outerHTML
                                                );
                                            }}
                                            className="cursor-pointer ml-2 inline h-5 w-5"
                                            color="gray"
                                            data-tip="Open in new tab"
                                          />
                                          <ClipboardIcon
                                            onClick={() =>
                                              copyToClipboard(
                                                handleFilePath(
                                                  values.competitors[0]
                                                    .competitorAsinRevenueScreenshot
                                                )
                                              )
                                            }
                                            className="cursor-pointer ml-2 inline h-5 w-5"
                                            color="gray"
                                            data-tip="Copy image"
                                          />
                                          <ReactTooltip
                                            place="bottom"
                                            className="max-w-xs text-black"
                                            backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                                            textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                                          />
                                        </Label>

                                        <img
                                          id="target"
                                          src={handleFilePath(
                                            values.competitors[0]
                                              .competitorAsinRevenueScreenshot
                                          )}
                                          data-tip={
                                            values.competitors[0]
                                              .competitorAsinRevenueScreenshotDateStamp
                                          }
                                          className="border-2 border-indigo-600"
                                        />
                                        <ReactTooltip
                                          place="bottom"
                                          className="max-w-xs text-black"
                                          backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                                          textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                                        />
                                      </div>
                                    ) : (
                                      <div className="col-span-12 row-span-3">
                                        <div className="flex flex-row justify-between">
                                          <div className="flex flex-col">
                                            <Label>
                                              Competitor ASIN Revenue Screenshot
                                              {/* <RequiredField /> */}
                                            </Label>
                                            <input
                                              id="upload"
                                              className="pt-2 focus:outline-none"
                                              type="file"
                                              accept="image/*"
                                              onChange={(e) =>
                                                onUploadImage(
                                                  e,
                                                  setFieldValue,
                                                  'competitorAsinRevenueScreenshot',
                                                  'competitorAsinRevenueScreenshotDateStamp',
                                                  values
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        </div>
                      </>
                    )}

                    <div className="col-span-1">
                      {[
                        'Unprocessed New Leads',
                        'Old-Leads',
                        'Shopify',
                        'Instagram',
                        'For Approval',
                        'Ready to Pitch',
                        'Connection Request Sent',
                        ...STATUSES_FOLLOW_UP,
                      ].includes(status) &&
                        action !== 'add' && (
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  onClick={() => {
                                    setFieldValue(
                                      'pitchTemplate',
                                      pitchTemplateMaker(values, variables)
                                    );
                                  }}
                                  disabled={showAssignedButton}
                                  className="flex w-full justify-between py-2 text-left text-lg tracking-tight text-gray-700  font-bold border-b"
                                >
                                  <span>Pitch Template</span>
                                  <ChevronUpIcon
                                    className={`${
                                      open ? 'rotate-180 transform' : ''
                                    } h-6 w-6 text-gray-500`}
                                  />
                                </Disclosure.Button>

                                <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                                  <div className="col-span-12">
                                    <Label>Manually Change Template</Label>
                                    <Field
                                      value={selectedTemplate}
                                      as="select"
                                      className="form-select text-sm capitalize"
                                      onChange={(e) => {
                                        handleChange(e);
                                        onChangeTemplate(
                                          e,
                                          values,
                                          setFieldValue
                                        );
                                      }}
                                    >
                                      <option value={''}>
                                        Auto generated template
                                      </option>
                                      {availablePitchTemplates.map((el) => (
                                        <option key={el.key} value={el.key}>
                                          {el.key}
                                        </option>
                                      ))}
                                    </Field>
                                  </div>

                                  <div className="flex justify-between py-3">
                                    <div className="flex flex-row">
                                      <span className="">Pitch Template</span>
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFieldValue(
                                            'pitchTemplate',
                                            pitchTemplateMaker(
                                              values,
                                              variables,
                                              selectedTemplate
                                            )
                                          );
                                        }}
                                        data-tip="Reload Template"
                                        className="pl-2 pb-3"
                                      >
                                        <RefreshIcon
                                          className="h-5 w-5"
                                          color="gray"
                                        />
                                        <ReactTooltip
                                          place="top"
                                          className="max-w-xs text-black"
                                          backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                                          textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                                        />
                                      </button>
                                    </div>
                                    <div>
                                      {canSendEmail(values) &&
                                        liAccountsOptions.find(
                                          (el) =>
                                            el.linkedInAccountId ===
                                            values.linkedInAccountId
                                        )?.usedForEmail && (
                                          <Button
                                            roundedSize="2xl"
                                            classes="tracking-wider font-bold"
                                            px={6}
                                            py={2}
                                            textSize="xs"
                                            color="green"
                                            onClick={(e) => {
                                              setOpenConfirmPitchViaEmail(true);
                                            }}
                                          >
                                            Send Pitch via Email
                                          </Button>
                                        )}

                                      <button
                                        onClick={(e) =>
                                          onCopyTemplate(
                                            e,
                                            values.pitchTemplate
                                          )
                                        }
                                        data-tip="Copy template"
                                        className="px-3 pt-2"
                                      >
                                        <ClipboardIcon
                                          className="h-5 w-5"
                                          color="gray"
                                        />
                                        <ReactTooltip
                                          place="top"
                                          className="max-w-xs text-black"
                                          backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                                          textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                                        />
                                      </button>
                                    </div>
                                    <ConfirmationModal
                                      title="Confirm Pitch Via Email"
                                      content={`Are you sure you want to Send Pitch Via Email? `}
                                      open={openConfirmPitchViaEmail}
                                      setOpen={setOpenConfirmPitchViaEmail}
                                      onOkClick={(e) => {
                                        onSendViaEmail(
                                          e,
                                          values.pitchTemplate,
                                          values.email,
                                          values.linkedInAccountId,
                                          values
                                        );
                                      }}
                                      onCancelClick={() => {
                                        setOpenConfirmPitchViaEmail(false);
                                      }}
                                    />
                                  </div>

                                  <Field name="pitchTemplate">
                                    {({ field }) => (
                                      <ReactQuill
                                        value={field.value}
                                        theme="snow"
                                        ref={contentRef}
                                        modules={{
                                          toolbar: false,
                                        }}
                                      />
                                    )}
                                  </Field>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )}
                    </div>

                    <div className="col-span-1 pt-3">
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <Button
                          onClick={() => checkErrors(errors)}
                          type="submit"
                          classes="bg-blue-900 rounded-2xl px-12 font-bold"
                          color="sky"
                          disabled={isDisabled && !userCan('leads.manager')}
                        >
                          Save
                        </Button>
                      )}
                    </div>

                    <div className="col-span-1"></div>
                  </div>
                </div>
              </div>

              {id !== 'create' && (
                <>
                  {showUpdatesSection ? (
                    <div className="col-span-5">
                      <div className="client-updates container-fixed bg-gray-50 border-l border-gray-100">
                        <Updates
                          setVisible={(value) =>
                            dispatch(setShowUpdatesSection(value))
                          }
                          refresh={refresh}
                          setRefresh={setRefresh}
                          linkedInEmail={
                            liAccountsOptions.find(
                              (el) =>
                                el.linkedInAccountId ===
                                values.linkedInAccountId
                            )
                              ? liAccountsOptions.find(
                                  (el) =>
                                    el.linkedInAccountId ===
                                    values.linkedInAccountId
                                ).email
                              : ''
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <UpdatesToggle
                      onToggle={(value) =>
                        dispatch(setShowUpdatesSection(value))
                      }
                    />
                  )}
                </>
              )}
              <Modal
                open={isLemlistFormOpen}
                setOpen={setIsLemlistFormOpen}
                as={'div'}
                align="middle"
                noOverlayClick={true}
                persistent={true}
              >
                <LemlistAcceptedModal
                  setOpen={setIsLemlistFormOpen}
                  formValues={values}
                  onUpdateLead={onUpdateLead}
                  checkErrors={checkErrors}
                />
              </Modal>
            </div>
          </Form>
        )}
      </Formik>

      <LeadStatusSlideOver
        open={isStatusOpen}
        setOpen={setIsStatusOpen}
        onUpdateLead={onUpdateLead}
        formRef={formRef}
        copyToClipboard={copyToClipboard}
        onUploadImage={onUploadImage}
        onPhasteImage={onPhasteImage}
        status={currentStatus}
      />

      <ConfirmationModal
        title={
          pitchCondition
            ? `Exact Match!`
            : 'Exact Match Found, Do you want update anyway?'
        }
        content={
          pitchCondition ? (
            <div>
              <span>Cannot be updated until 3 months after pitched date.</span>
            </div>
          ) : (
            <div>
              <label className="text-xs mt-4 mx-5 flex justify-center items-center">
                <span className="ml-2 text-red-700 text-left">
                  Lead Name: {exactMatch ? exactMatch.lead : ''}
                </span>
              </label>
              <label className="text-xs mt-4 mx-5 flex justify-center items-center">
                <span className="ml-2 text-red-700 text-left">
                  Company Name: {exactMatch ? exactMatch.companyName : ''}
                </span>
              </label>
            </div>
          )
        }
        open={isOpenConfirmation}
        setOpen={setIsOpenConfirmation}
        onOkClick={() => onUpdateExistingRecord()}
        onCancelClick={() => setIsOpenConfirmation(false)}
        showButtons={!pitchCondition}
      />

      <ConfirmationModal
        title={`Update this record?`}
        content={
          <div>
            <label className="text-xs mt-4 mx-5 flex justify-center items-center">
              <p className="ml-2 text-gray-700 text-left">
                Lead Name: {newVal.lead}
              </p>
              <p className="ml-2 text-gray-700 text-left">
                Company Name: {newVal.companyName}
              </p>
            </label>
            <label className="text-xs mt-4 mx-5 flex justify-center items-center">
              <span className="ml-2 text-red-700 text-left">
                Status: {status} to {newVal.status}
              </span>
            </label>
          </div>
        }
        open={isOpenConfirmationUpdate}
        setOpen={setIsOpenConfirmationUpdate}
        onOkClick={() => onConfirmUpdate()}
        onCancelClick={() => setIsOpenConfirmationUpdate(false)}
      />
    </>
  );
};

export default LeadForm;
